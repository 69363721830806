module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#FF54AC","display":"minimal-ui","icon":"/opt/build/repo/static/images/Phonica-SVG-Only logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3582ac021a3bd9256d4372d69481a5c2"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"file":"https://fonts.googleapis.com/css2?family=PT+Serif&display=swap","name":"PT Serif"}],"custom":[{"name":"Inter","file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
